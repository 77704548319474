
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import Campo from "../../../store/entities/OZONE/campo";
import RuleCheckbox from "./ruleCheckbox.vue";
import Rules from "../../../store/entities/OZONE/rules";
import PeopleHeader from "../../../components/People/people-header.vue";

@Component({
  components: { RuleCheckbox, PeopleHeader },
})
export default class EditCampo extends AbpBase {
  name: "edit-campo";
  // @Prop({ type: Boolean, default: false }) value: boolean
  // @Prop({ type: Boolean, default: false }) editing: boolean
  campo: Campo = new Campo();
  title: String = "";
  tipoCampo: any = null;
  listSelectedRules = [];
  selectedRules: Array<any> = [];
  values: Array<any> = [];
  editing: boolean;
  rules: Array<any> = [];
  disabled: boolean = false;

  log(item) {
    console.log(item);
  }

  get list() {
    return this.$store.state.campo.listPropertyType;
  }

  get listRules() {
    return this.$store.state.campo.listRules;
  }
  async created() {
    await this.$store.dispatch({
      type: "campo/getAllRules",
    });
  }
  async save() {
    if (this.checkFormValidity()) {
      this.campo.tipo = this.tipoCampo.value;
      this.campo.listSelectedRules = JSON.stringify(this.selectedRules);
      if (this.editing) {
        await this.$store
          .dispatch({
            type: "campo/update",
            data: this.campo,
          })
          .then(() => this.close());
      } else {
        await this.$store
          .dispatch({
            type: "campo/create",
            data: this.campo,
          })
          .then(() => this.close());
      }
    }
  }
  close() {
    this.resetFields();
    this.$router.go(-1);
  }
  beforeMount() {
    this.editing = this.$route.params.id != null;
    if (this.editing) {
      this.listSelectedRules = [];
      this.values = [];
      this.campo = Util.extend(true, {}, this.$store.state.campo.editCampo);
      this.tipoCampo = this.$store.state.campo.listPropertyType.find(
        (x) => x.value == this.campo.tipo
      );
      if (this.campo.listSelectedRules != null) {
        this.rules = JSON.parse(this.campo.listSelectedRules);
        this.rules.forEach((rule) => {
          this.listSelectedRules.push(Object.keys(rule)[0]);
        });
        this.selectedRules = this.rules;
      }
    }
    if (this.editing) {
      this.title = "Editar Campo";
    } else {
      this.title = "Crear Campo";
    }
    this.setDisabled();
  }
  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted(
      "OZONE.Campos.Edit",
      "OZONE.Campos.Create",
      "OZONE.Campos.Delete"
    );
  }

  manageExistantRule(existantRule: any, data: any, activated: boolean) {
    if (existantRule !== undefined) {
      this.selectedRules.splice(this.selectedRules.indexOf(existantRule), 1);
    }
    if (activated) {
      this.selectedRules.push(data);
    }
  }

  between(activated: boolean, min: Number, max: number) {
    const data = {
      between: {
        min: min,
        max: max,
      },
    };
    const existantRule = this.selectedRules.find(
      (x) => x.between !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  alpha(activated: boolean) {
    const data = {
      alpha: true,
    };
    const existantRule = this.selectedRules.find((x) => x.alpha !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  alpha_dash(activated: boolean) {
    const data = {
      alpha_dash: true,
    };
    const existantRule = this.selectedRules.find(
      (x) => x.alpha_dash !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  alpha_num(activated: boolean) {
    const data = {
      alpha_num: true,
    };
    const existantRule = this.selectedRules.find(
      (x) => x.alpha_num !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  alpha_spaces(activated: boolean) {
    const data = {
      alpha_spaces: true,
    };
    const existantRule = this.selectedRules.find(
      (x) => x.alpha_spaces !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  digits(activated: boolean, number: number) {
    const data = {
      digits: number,
    };
    const existantRule = this.selectedRules.find((x) => x.digits !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  email(activated: boolean) {
    const data = {
      email: true,
    };
    const existantRule = this.selectedRules.find((x) => x.email !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  integer(activated: boolean) {
    const data = {
      integer: true,
    };
    const existantRule = this.selectedRules.find(
      (x) => x.integer !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  length(activated: boolean, number: number) {
    const data = {
      length: number,
    };
    const existantRule = this.selectedRules.find((x) => x.length !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  max(activated: boolean, number: number) {
    const data = {
      max: number,
    };
    const existantRule = this.selectedRules.find((x) => x.max !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  min(activated: boolean, number: number) {
    const data = {
      min: number,
    };
    const existantRule = this.selectedRules.find((x) => x.min !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  numeric(activated: boolean) {
    const data = {
      numeric: true,
    };
    const existantRule = this.selectedRules.find(
      (x) => x.numeric !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  regex(activated: boolean, regex: string) {
    const data = {
      regex: regex,
    };
    const existantRule = this.selectedRules.find((x) => x.regex !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  required(activated: boolean) {
    const data = {
      required: true,
    };
    const existantRule = this.selectedRules.find(
      (x) => x.required !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  double(activated: boolean) {
    const data = {
      double: true,
    };
    const existantRule = this.selectedRules.find((x) => x.double !== undefined);
    this.manageExistantRule(existantRule, data, activated);
  }
  dateFormat(activated: boolean) {
    const data = {
      date_format: true,
    };
    const existantRule = this.selectedRules.find(
      (x) => x.date_format !== undefined
    );
    this.manageExistantRule(existantRule, data, activated);
  }
  checkFormValidity() {
    const valid = (this.$refs.campoForm as any).checkValidity();
    return valid;
  }
  resetFields() {
    this.campo = new Campo();
  }
  campoRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Nombre")),
      placeholder: this.L("Nombre"),
      trigger: "blur",
    },
    tipo: {
      required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Tipo")),
      placeholder: this.L("Tipo"),
      trigger: "blur",
      type: "number",
    },
  };
}
